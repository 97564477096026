.app-container {
  display: grid;
  height: 100vh;
  overflow: auto;
}

.header {
  background-color: #CB3234;
}

.app-content {
  overflow: auto;

}
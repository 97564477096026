:root {
  --app-background: white;
  --gray-background: 17, 35, 48;
  --red: 203, 50, 52;
}

html {
  font-size: 14px;
}

@media (max-width: 960px) {
  html {
    font-size: 14px
  }
}

body {
  font-family: 'Moscow Sans' !important;
  margin: 0;
  background-color: var(--app-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  & * {
    user-select: none;
  }

  /* Firefox */
  & * {
    scrollbar-width: thin;
    scrollbar-color: #C6C6C6 transparent; //#FFFFFF;
  }

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent; // #FFFFFF;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #bbbbbb;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #bbbbbb;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent; // #C6C6C6;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #606060;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #606060;
  }

}

/* KBL custom styles */

.h12 {
  height: 0.75rem
}

*:focus {
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

.with-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    font-family: 'Moscow Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
  }
}

a#pdf-download {
  display: none;
}

#pdf-controls {
  z-index: 1000;
}

/* /KBL custom styles */

.sidebar {
  display: grid;
  overflow: auto;
  grid-template-rows: auto 1fr 64px;
  --padding: 20px 10px 10px 20px;
  background-color: rgba(var(--gray-background), 0.04);

  .account {
    display: grid;
    padding: var(--padding);
  }

  .search {
    padding-top: 10px;
    padding-inline: 20px;
    text-align: center;
  }

  .content {
    overflow: auto;
  }

  .footer {
    width: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: var(--mui-palette-primary-text03);
    padding: var(--padding);
    border-top: 1px solid white;
    justify-content: space-between;
  }
}
